// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-contact-page-column/index.js'; import '../blocks/gutenberg/acf-contact-page-columns/index.js'; import '../blocks/gutenberg/acf-example-block/index.js'; import '../blocks/gutenberg/acf-new-products-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/* global ajax_object */
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */

  $('a[href="#"]').click(function () {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      'slow'
    );
    return false;
  });

  $('.open-accordion-menu').on('click', function (e) {
    e.preventDefault();
    $('.menu-product-menu-container').slideToggle();
    $(this).toggleClass('active');
  });
  $('.search-trigger').on('click', function (e) {
    e.preventDefault();
    if (screen.width < 767) {
      $('.header__search.mobile-v').slideToggle();
    }
  });
  $('.summary_row-count').on('click', function (e) {
    e.preventDefault();
    if (screen.width < 767) {
      $('.woocommerce-mini-cart').slideToggle();
    }
  });
  $('.matchHeight').matchHeight();

  $('.coupon-cloned input').on('input', function () {
    const value = $(this).val();
    $('.coupon input').val(value);
  });
  $('.input-text-cloned-email').on('input', function () {
    const value = $(this).val();
    $('input#reg_email').val(value);
  });
  $('.coupon-cloned .button').on('click', function () {
    $('.coupon .button').trigger('click');
  });
  $('.trigger-register-form').on('click', function () {
    $('.register-form-wrapper').fadeIn();
  });
  $('#register').on('click', function () {
    if ($(this).prop('checked')) {
      $('.register-form-wrapper').css('display', 'block');
    } else {
      $('.register-form-wrapper').css('display', 'none');
    }
  });

  $('.button-close').on('click', function (e) {
    e.preventDefault();
    $('.register-form-wrapper').fadeOut();
  });

  function updateUrlWithFilters(minPrice, maxPrice) {
    var currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('min_price', minPrice);
    if (maxPrice !== '') {
      currentUrl.searchParams.set('max_price', maxPrice);
    } else {
      currentUrl.searchParams.delete('max_price');
    }
    window.location.href = currentUrl.toString();
  }

  $('.price-filter').click(function () {
    var minPrice = $(this).data('min');
    var maxPrice = $(this).data('max');
    updateUrlWithFilters(minPrice, maxPrice);
  });

  var minPrice = new URLSearchParams(window.location.search).get('min_price');
  var maxPrice = new URLSearchParams(window.location.search).get('max_price');
  if (minPrice !== null || maxPrice !== null) {
    var filterText =
      'Price: ' +
      '$' +
      minPrice +
      (maxPrice ? ' - $' + maxPrice : ' and above');
    var filterElement =
      '<span class="remove-filter price" data-min="' +
      minPrice +
      '" data-max="' +
      maxPrice +
      '">' +
      filterText +
      ' <span class="">x</span></span>';
    $('.shop-sidebar__head .applied-filter').replaceWith(filterElement).show();
  }
  $(document).on('click', '.remove-filter', function () {
    var currentUrl = new URL(window.location.href);

    currentUrl.searchParams.delete('min_price');
    currentUrl.searchParams.delete('max_price');

    window.location.href = currentUrl.toString();
  });

  $('.add-to-wishlist').on('click', function (e) {
    console.log('test');
    e.preventDefault();
    var productId = $(this).data('product-id');

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'custom_add_to_wishlist',
        product_id: productId,
      },
      success: function () {
        $('.add-to-wishlist-added').text('Added to your wishlist').fadeIn();
      },
      error: function (error) {
        console.error('Error adding to wishlist:', error);
      },
    });
  });

  $(document).on('click', '.add-to-compare', function (e) {
    e.preventDefault();
    var product_id = $(this).data('product-id');

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'add_to_compare_list',
        product_id: product_id,
      },
      dataType: 'json',
      success: function () {
        location.reload();
      },
      error: function (xhr, status, error) {
        alert('Error: ' + error);
      },
    });
  });
  $(document).on('click', '.remove-from-compare', function (e) {
    e.preventDefault();
    var product_id = $(this).data('product-id');

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'remove_from_compare_list',
        product_id: product_id,
      },
      success: function (response) {
        var data = JSON.parse(response);
        if (data.status === 'success') {
          location.reload();
        } else {
          alert('Error: ' + (data.message || 'Unknown error'));
        }
      },
      error: function (xhr, status, error) {
        alert('Error: ' + error);
      },
    });
  });
  $(document).on('click', '.compare-link', function (e) {
    e.preventDefault();
    var url = $(this).attr('href');
    var width = 800;
    var height = 600;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;

    window.open(
      url,
      'compareWindow',
      'width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left +
        ', resizable=yes, scrollbars=yes'
    );
  });

  function updateUrlWithParams() {
    var selectedLimit = $('.select-count-of-post').val();
    var selectedSort = $('.prod-filtr').val();
    var currentUrl = window.location.href;
    var newUrl;
    currentUrl = currentUrl.replace(/\/page\/\d+/g, '');
    var baseUrl = currentUrl.split('?')[0];
    var params = new URLSearchParams(window.location.search);
    params.set('sort_by', selectedSort);
    params.set('limit', selectedLimit);
    newUrl = baseUrl + '?' + params.toString();
    window.location.href = newUrl;
  }

  // Handle changes in the posts per page dropdown
  $('.select-count-of-post').on('change', function () {
    updateUrlWithParams();
  });

  // Handle changes in the sort by dropdown
  $('.prod-filtr').on('change', function () {
    updateUrlWithParams();
  });

  // Handle changes in the order button
  $('.change-order-btn').on('click', function () {
    var currentOrder = $(this).data('order');
    var newOrder = currentOrder === 'ASC' ? 'DESC' : 'ASC';
    $(this).data('order', newOrder);

    // Update the URL with the new order parameter
    var currentUrl = window.location.href;
    currentUrl = currentUrl.replace(/\/page\/\d+/g, '');

    var baseUrl = currentUrl.split('?')[0];
    var params = new URLSearchParams(window.location.search);

    // Toggle the order parameter
    params.set('order', newOrder);

    var selectedLimit = $('.select-count-of-post').val();
    var selectedSort = $('.prod-filtr').val();

    params.set('sort_by', selectedSort);
    params.set('limit', selectedLimit);

    var newUrl = baseUrl + '?' + params.toString();
    window.location.href = newUrl;
  });

  //CHECKOUT PAGE SCRIPTS
  $('#guest').on('click', function () {
    $('.registration-btn').fadeIn();
  });

  var hiddenMethodValue = $('.ship_method').val();

  $('#shipping_method input[type="radio"]').prop('checked', false);

  var matchedRadio = $('#shipping_method input[type="radio"]').filter(
    function () {
      return $(this).val() === hiddenMethodValue;
    }
  );
  if (matchedRadio.length > 0) {
    matchedRadio.prop('checked', true);
  } else {
    $('#shipping_method input[type="radio"]:last').prop('checked', true);
  }
  $('#shipping_method input[type="radio"]:checked').trigger('change');

  $('.custom-desc__item').on('click', function () {
    if (screen.width < 767) {
      if ($(this).children('p').length) {
        $(this).children('p').slideToggle();
      } else {
        $(this).children('.product-details-table').slideToggle();
      }
    }
  });
  $('.sidebar-mini-cart').on('click', function () {
    if (screen.width < 767) {
      $('.sidebar-mini-cart__head').slideToggle();
      $('.sidebar-mini-cart__recently-added').slideToggle();
      $('.sidebar-mini-cart__list ').slideToggle();
    }
  });
  $('.shop-sidebar__head-title').on('click', function () {
    if (screen.width < 767) {
      $('.shop-sidebar__head-text').slideToggle();
      $('.shop-sidebar__cat-list-wrap').slideToggle();
      $('.price-wrapper').slideToggle();
    }
  });
  $('.registration-btn').on('click', function () {
    if ($('#guest').is(':checked')) {
      $('.billing-accordion').addClass('is-active');
      $('.reg-accordion').addClass('is-active');
      $('.billing-accordion .accordion-content').css('display', 'block');
      $('.reg-accordion .accordion-content').css('display', 'none');
    }
  });
  $('.billing-btn').on('click', function () {
    const firstName = $('#billing_first_name').val();
    const secondName = $('#billing_last_name').val();
    const firstAddress = $('#billing_address_1').val();
    const secondAddress = $('#billing_address_2').val();
    const city = $('#billing_city').val();
    const state = $('#select2-billing_state-container').text();
    const zip = $('#billing_postcode').val();
    const country = $('#select2-billing_country-container').text();
    const tel = $('#billing_phone').val();
    const billingInfo = $('.wrap-billing');
    const totalCash = $('#shipping_method');
    $('.billing-name').text(firstName);
    $('.billing-sname').text(secondName);
    $('.billing-address').text(firstAddress);
    $('.billing-address2').text(secondAddress);
    $('.billing-city').text(city);
    $('.billing-state').text(state);
    $('.billing-zip').text(zip);
    $('.billing-country').text(country);
    $('.billing-tel').text('T:' + tel);
    $('.shipping_address').css('display', 'none');
    if ($('#thiss').is(':checked')) {
      billingInfo.clone().appendTo($('#shipping-progress-opcheckout'));
      $('#shipping_first_name').val(firstName);
      $('#shipping_last_name').val(secondName);
      $('#shipping_city').val(city);
      $('#shipping_postcode').val(zip);
      $('#shipping_address_1').val(firstAddress);
      $('#shipping_address_2').val(secondAddress);
      $('#shipping_phone').val(tel);
      if (
        $('#shipping-progress-opcheckout .wrap-billing.info-delivery').length >
        1
      ) {
        $('#shipping-progress-opcheckout .wrap-billing.info-delivery')
          .not(':last')
          .remove();
      }
      totalCash
        .clone()
        .appendTo($('.shipping-method-accordion .accordion-content'));

      if ($('.shipping-method-accordion #shipping_method').length > 1) {
        $('.shipping-method-accordion #shipping_method').not(':last').remove();
      }
      $('#shipping-progress-opcheckout .change-edits').css(
        'display',
        'inline-block'
      );
      $('#billing-progress-opcheckout .change-edits').css(
        'display',
        'inline-block'
      );
      $('.shipping-method-accordion').addClass('is-active');
      $('.shipping-method-accordion .accordion-content').css('display', 'flex');

      $('.billing-accordion').removeClass('is-active');
      $('.billing-accordion .accordion-content').css('display', 'none');
    }
    if ($('#different').is(':checked')) {
      $('.shipping-accordion').addClass('is-active');
      $('.shipping-accordion .accordion-content').css('display', 'block');
      $('.billing-accordion').removeClass('is-active');
      $('.billing-accordion .accordion-content').css('display', 'none');
      $('#shipping-progress-opcheckout .wrap-billing').empty();
      $('.shipping_address').css('display', 'block');
      $('#ship-to-different-address-checkbox').prop('checked', true);
      $('#billing-progress-opcheckout .change-edits').css(
        'display',
        'inline-block'
      );
      totalCash
        .clone()
        .appendTo($('.shipping-method-accordion .accordion-content'));
    }
  });

  $('.shipping-btn').on('click', function () {
    const firstName = $('#shipping_first_name').val();
    const secondName = $('#shipping_last_name').val();
    const firstAddress = $('#shipping_address_1').val();
    const secondAddress = $('#shipping_address_2').val();
    const city = $('#shipping_city').val();
    const state = $('#select2-shipping_state-container').text();
    const zip = $('#shipping_postcode').val();
    const country = $('#select2-shipping_country-container').text();
    const tel = $('#shipping_phone').val();
    if ($('#different').is(':checked')) {
      $('.shipping-name').text(firstName);
      $('.shipping-sname').text(secondName);
      $('.shipping-address').text(firstAddress);
      $('.shipping-address2').text(secondAddress);
      $('.shipping-city').text(city);
      $('.shipping-state').text(state);
      $('.shipping-zip').text(zip);
      $('.shipping-country').text(country);
      $('.shipping-tel').text('T:' + tel);
      $('#shipping-progress-opcheckout .change-edits').css(
        'display',
        'inline-block'
      );
      $('.shipping-accordion').removeClass('is-active');
      $('.shipping-accordion .accordion-content').css('display', 'none');
      $('.shipping-method-accordion').addClass('is-active');
      $('.shipping-method-accordion .accordion-content').css('display', 'flex');
    }
  });
  $('.shipping-method-btn').on('click', function () {
    $('.shipping-method-accordion').removeClass('is-active');
    $('.review-accordion').addClass('is-active');
    $('.shipping-method-accordion .accordion-content').css('display', 'none');
    $('.review-accordion .accordion-content').css('display', 'block');
    $('#shipping_method-progress-opcheckout .change-edits').css(
      'display',
      'inline-block'
    );
    const checkedInput = $('#shipping_method input.shipping_method:checked');
    const checkedLabel = checkedInput.closest('li').find('label');
    const finalLabelPrice = checkedLabel.text().trim();
    const labelSpan = $('<span>').text(finalLabelPrice);
    const $targetElement = $('#shipping_method-progress-opcheckout .price');
    const orderTotalPrice = $('.woocommerce-shipping-totals td');
    if ($targetElement.length) {
      $targetElement.empty().append(labelSpan);
    } else {
      $('#shipping_method-progress-opcheckout').append(labelSpan);
    }

    if (orderTotalPrice.length) {
      orderTotalPrice.empty().append(finalLabelPrice);
    }
  });
  $('.order-btn').on('click', function () {
    $('.review-accordion').removeClass('is-active');
    $('.payment-accordion').addClass('is-active');
    $('.review-accordion .accordion-content').css('display', 'none');
    $('.payment-accordion .accordion-content').css('display', 'block');
  });

  $('#shipping_method-progress-opcheckout .change-edits').on(
    'click',
    function (e) {
      e.preventDefault();
      $('.shipping-method-accordion').addClass('is-active');
      $('.shipping-method-accordion .accordion-content').css(
        'display',
        'block'
      );
      $('.review-accordion .accordion-content').css('display', 'none');
      $('.review-accordion').removeClass('is-active');
    }
  );
  $('#billing-progress-opcheckout .change-edits').on('click', function (e) {
    e.preventDefault();
    $('.billing-accordion').addClass('is-active');
    $('.billing-accordion .accordion-content').css('display', 'block');
  });
  $('#shipping-progress-opcheckout .change-edits').on('click', function (e) {
    e.preventDefault();
    $('.shipping-accordion').addClass('is-active');
    $('.shipping-accordion .accordion-content').css('display', 'block');
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * WooCommerce
   */
  $('body').on('added_to_cart', function () {
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'update_cart_info',
      },
      success: function (response) {
        $('.header__cart-total').html(response.cart_total);
        $('.summary_row-count').text(response.total_items);
        $('.mini-cart').replaceWith(response.mini_cart);
      },
    });
  });

  $(document).on('click', function (event) {
    if (screen.width > 767) {
      if (
        !$(event.target).closest('.header__cart').length &&
        !$(event.target).closest('.woocommerce-mini-cart').length
      ) {
        $('.woocommerce-mini-cart').slideUp();
      }
    }
  });

  $('.header__cart').on('click', function () {
    if (screen.width > 767) {
      $('.woocommerce-mini-cart').slideToggle();
    }
  });

  // $('.price-filter').on('click', function (e) {
  //   e.preventDefault();
  //   var priceRange = $(this).data('range');
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     type: 'post',
  //     data: {
  //       action: 'filter_products',
  //       price_range: priceRange,
  //     },
  //     success: function (response) {
  //       $('.products').html(response);
  //     },
  //   });
  // });

  //product menu
  $('.menu-item-has-children').mouseenter(function () {
    $(this).find('.submenu').addClass('submenu-active');
  });
  $('.menu-item-has-children').mouseleave(function () {
    $(this).find('.submenu').removeClass('submenu-active');
  });

  // $('.price-filter').click(function () {
  //   var minPrice = $(this).data('min');
  //   var maxPrice = $(this).data('max');
  //
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     type: 'POST',
  //     data: {
  //       action: 'filter_products',
  //       min_price: minPrice,
  //       max_price: maxPrice,
  //     },
  //     success: function (response) {
  //       $('.products').html(response);
  //     },
  //   });
  // });

  // Add quantity to product
  $(document).on('change', 'li.product .quantity input.qty', function (e) {
    e.preventDefault();
    var add_to_cart_button = $(this)
      .closest('li.product')
      .find('a.add_to_cart_button');
    // For AJAX add-to-cart actions.
    add_to_cart_button.attr('data-quantity', $(this).val());
    // For non-AJAX add-to-cart actions.
    add_to_cart_button.attr(
      'href',
      '?add-to-cart=' +
        add_to_cart_button.attr('data-product_id') +
        '&quantity=' +
        $(this).val()
    );
  });

  // $('#posts_per_page').on('change', function () {
  //   this.form.submit();
  // });

  var selectedFilter = localStorage.getItem('selectedFilter');
  if (selectedFilter) {
    $('#product-filter').val(selectedFilter);
  }

  // $('#product-filter').change(function () {
  //   var filter = $(this).val();
  //
  //   var url = window.location.href.split('?')[0];
  //   var query_string = window.location.search;
  //
  //   if (filter !== 'default') {
  //     if (query_string) {
  //       url += '?' + query_string + '&orderby=' + filter;
  //     } else {
  //       url += '?orderby=' + filter;
  //     }
  //   }
  //
  //   window.location.href = url;
  //
  //   $(this).find('option').removeAttr('selected');
  //   $(this)
  //     .find('option[value="' + filter + '"]')
  //     .attr('selected', 'selected');
  //
  //   localStorage.setItem('selectedFilter', filter);
  // });
  //
  // $('#change-order-btn').on('click', function () {
  //   var currentCategory = $('#current-category').val();
  //   var currentOrder = $(this).data('order');
  //
  //   var newOrder = currentOrder === 'ASC' ? 'DESC' : 'ASC';
  //
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     type: 'post',
  //     data: {
  //       action: 'change_order',
  //       current_category: currentCategory,
  //       order: newOrder,
  //     },
  //     success: function (response) {
  //       $('.products').html(response);
  //
  //       $('#change-order-btn').data('order', newOrder);
  //     },
  //   });
  // });

  $('form.cart').on('click', 'button.plus, button.minus', function () {
    // Get current quantity values
    var qty = $(this).closest('form.cart').find('.qty');
    var val = parseFloat(qty.val());
    var max = parseFloat(qty.attr('max'));
    var min = parseFloat(qty.attr('min'));
    var step = parseFloat(qty.attr('step'));

    // Change the value if plus or minus
    if ($(this).is('.plus')) {
      if (max && max <= val) {
        qty.val(max);
      } else {
        qty.val(val + step);
      }
    } else {
      if (min && min >= val) {
        qty.val(min);
      } else if (val > 1) {
        qty.val(val - step);
      }
    }
  });

  //sitemap button tab
  $('.cat-button').on('click', function () {
    $('.sitemap-wrap__products').hide();
    $('.sitemap-wrap__categories').show();
  });
  $('.prod-button').on('click', function () {
    $('.sitemap-wrap__products').show();
    $('.sitemap-wrap__categories').hide();
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
